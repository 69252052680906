.button {
  background: linear-gradient(
      0deg,
      hsla(0, 0%, 89%, 0.08),
      hsla(0, 0%, 89%, 0.08)
    ),
    #004a77;
  color: #c2e7ff;
  outline: none;
  border: none;
  border-radius: 40px;
  font-family: inherit;
  padding: 14px 40px;
  letter-spacing: 1.2px;
  font-variation-settings: 'GRAD' 125;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  font-size: 16px;
}

.button:hover {
  background-color: #004a77 !important;
}

/* .button:hover {
  background-color: #eee;
} */

.rotating-border {
  width: max-content;
  background: linear-gradient(90deg, white 50%, transparent 50%),
    linear-gradient(90deg, white 50%, transparent 50%),
    linear-gradient(0deg, white 50%, transparent 50%),
    linear-gradient(0deg, white 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  animation: border-dance 2s infinite linear;
}

@keyframes border-dance {
  0% {
    background-position: 0 0, 100% 100%, 0 100%, 100% 0;
  }
  100% {
    background-position: 100% 0, 0 100%, 0 0, 100% 100%;
  }
}
