img {
  position: absolute;
  image-rendering: optimizeSpeed;
  pointer-events: auto;
  user-select: none;
  /* pointer-events: none; */
  /* will-change: left, top; */
}

.layer {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  /* /overflow: hidden; */
  user-select: none;
  position: absolute;
  top: 0px;
  left: 0px;
  will-change: top, left;
}

.playground {
  border-radius: 40px;
  border: 2px solid orange;
}

#viewport {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  user-select: none;
}

#map {
  width: 2000px;
  height: 2000px;
  position: absolute;
  user-select: none;
  left: 0;
  top: 0;
}
