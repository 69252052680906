.input {
  display: block;
  box-sizing: border-box;
  margin: 0px;
  border-right: none;
  border-left: none;
  border-image: initial;
  /* border-bottom:  rgb(227, 226, 230, 0.6); */
  border-bottom: none;
  border-radius: 8px 8px 8px 8px;
  padding: 16px 18px 16px;
  width: 100%;
  font-weight: 500;
  height: inherit;
  color: #e3e3e3;
  background-color: rgb(227, 226, 230, 0.04);
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  caret-color: #aac7ff;
  transition: border-bottom 0.2s ease 0s, background-color 0.2s ease 0s;
}

.input::placeholder {
  font-weight: 600;
}

.input-label {
  text-transform: none;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Google Sans';
  position: relative;
  width: 100%;
  display: inline-block;
  overflow: hidden;
}

.input-text {
  inset: 0px;
  display: block;
  box-sizing: border-box;
  padding: 0px 0px 0px;
  color: #e3e3e3;
  font-size: 100%;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 18px;
  pointer-events: none;
  transition: color 0.2s ease 0s, font-size 0.2s ease 0s,
    line-height 0.2s ease 0s;
}

/* span:not(:focus):placeholder-shown {
  font-size: inherit;
  line-height: 48px;
} */

.input-text::after {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0px;
  display: block;
  width: 100%;
  height: 2px;
  background-color: #aac7ff;
  transform-origin: center bottom;
  transform: scaleX(0);
  transition: transform 0.3s ease 0s;
}

.input:hover {
  border-bottom-color: rgb(227, 226, 230, 0.87);
  background-color: rgb(227, 226, 230, 0.08);
}

.input:focus {
  outline: none;
}

.input-wrapper {
  position: relative;
  display: flex;
  width: 100%;
}

/* .input:focus::placeholder {
  color: #f9fbfe;
  filter: brightness(0.65);
  font-weight: 300;
} */

/* .input::placeholder {
  color: #f9fbfe;
  filter: brightness(0.65);
  font-weight: 300;
} */

.input-icon {
  position: absolute;
  top: 12px;
  left: 16px;
  color: #f9fbfe;
  filter: brightness(0.75);
  display: flex;
}
