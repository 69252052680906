body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Google Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1f1f1f;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**
This sections will house the
user avatar plus their username and
a notification icon on the left-most side
along with the context on the right-most side
*/

.context-section {
  padding-right: 20px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .context-section .room-indicator {
  color: 
} */

.context-section span:not(.chip-text) {
  margin-left: 20px;
  font-weight: 700;
  font-size: 16px;
}

.user-details {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 12px;
  font-family: inherit;
  font-weight: 400;
}

.username {
}

.avatar img {
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.menu-section {
  display: flex;
  margin-top: 20px;
  padding: 12px 30px;
  flex-direction: row;
  justify-content: space-between;
}

.menu-section span {
  font-weight: 900;
  font-size: 30px;
  font-family: inherit;
}

.search-section {
  display: flex;
  background-color: white;
  margin: 20px 30px 10px 30px;
  padding: 20px;
  border-radius: 16px;
  flex-direction: row;
  -webkit-box-shadow: 0px 2px 44px -27px rgba(189, 189, 189, 1);
  -moz-box-shadow: 0px 2px 44px -27px rgba(189, 189, 189, 1);
  box-shadow: 0px 2px 44px -27px rgba(189, 189, 189, 1);
}

.search-section .search-bar {
  display: flex;
  flex: 40%;
  flex-direction: column;
}

.search-section .search-bar span {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 12px;
  /* /letter-spacing: 0.5px; */
}

.search-section .status-field {
  display: flex;
  flex: 20%;
}

.search-section .category-field {
  display: flex;
  flex: 20%;
}

.search-section .search-button {
  flex: 20%;
  display: flex;
}

.data-section {
  margin: 20px 30px 30px 30px;
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 16px;
  -webkit-box-shadow: 0px 2px 44px -27px rgba(189, 189, 189, 1);
  -moz-box-shadow: 0px 2px 44px -27px rgba(189, 189, 189, 1);
  box-shadow: 0px 2px 44px -27px rgba(189, 189, 189, 1);
}

.data-section .title-bar {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px 34px;
}

.data-section .title-bar span {
  font-family: inherit;
  font-weight: 900;
  font-size: 24px;
  letter-spacing: 1px;
}

.puzzle-list {
  display: flex;
  flex-direction: column;
}

.puzzle-list .list-header {
  padding: 12px 60px;
  text-transform: uppercase;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  background-color: #f9faff;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  /* justify-content: space-between; */
}

.list-header div {
  flex: 20%;
}

.puzzle-list .list-header div {
  color: #686868;
  /* filter: brightness(0.3); */
}

.puzzle-list .puzzle-list-item {
  display: flex;
  padding: 8px 60px;
  margin-bottom: 6px;
  cursor: pointer;
}

.puzzle-list-item div {
  display: flex;
  align-items: center;
  flex: 20%;
}

.chip {
  border-radius: 26px;
  /* background-color: #4958fa4a; */
  /* background-color: #d4edda; */
  background-color: #4957fa25;
  color: #4957fa;
  padding: 6px 16px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/** DONE colors for chips
 backgroundColor: 'rgba(34, 228, 163, 0.207)',
 color: 'rgb(34, 228, 164)',
*/
.chip-text {
  /* filter: brightness(1); */
  font-family: inherit;
  font-size: 14px;
  text-decoration: underline;
  /* color: #4958fa; */
  /* color: #477f52; */
  /* text-transform: uppercase; */
  font-weight: 700;
  /* letter-spacing: 1.2px; */
}

.puzzle-list-item > div {
  font-weight: 600;
}

.puzzle-list-item .actions {
  gap: 10px;
  display: flex;
  color: black;
}

.puzzle-list-item .actions svg {
  color: #686868;
}

.sidebar {
  display: flex;
  flex: 16%;
  background-color: red;
}

.content {
  flex-direction: column;
  display: flex;
  flex: 84%;
  background-color: #f9fbfe;
}

main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex: 1;
  /* background-color: gray; */
  width: 100vw;
  /* height: 100vh; */
  box-sizing: border-box;
  height: 100vh;
}
