.element {
  margin-bottom: 10px;
}

.group {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.group > * {
  flex: 50%;
}

.overlay-button {
  font-family: 'CircularStdMedium' !important;
  padding: 6px 24px !important;
  font-size: 16px !important;
  text-transform: none !important;
  border-radius: 8px !important;
}

.input-element {
  background: rgb(242, 242, 242) !important;
}

.element > input[type='file'] {
  display: none;
  visibility: hidden;
}

.upload-file-button {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #4957fa64;
  border-radius: 24px;
  color: #4957fa;
  font-family: 'CircularStdMedium';
  padding: 8px 24px;
}

.body-section {
  margin-bottom: 20px;
  display: flex;
  font-weight: 500;
  align-items: center;
}

.body-section-icon {
  display: flex;
  margin-right: 14px;
  font-size: 16px;
  color: #4957fa;
}

.body-section-title {
  display: flex;
  font-size: 16px;
  font-family: 'CircularStdMedium';
}
