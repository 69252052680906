.top-menu {
  background-color: #28292a;
  display: flex;
  margin-left: 14%;
  margin-right: 14%;
  margin-top: 20px;
  /* padding: 28px; */
  border-radius: 50px;
  justify-content: space-between;
}

.top-menu-button {
  display: flex;
  padding: 28px;
  border-radius: 50px;
  flex: 40%;
  text-align: center;
  justify-content: center;
  color: rgb(196, 199, 197);
  font-size: 16px;
  letter-spacing: normal;
  font-weight: 500;
  line-height: 24px;
  font-variation-settings: 'GRAD' 0;
  font-family: 'Google Sans';
  transition: 0.1s;
  cursor: pointer;
}

.menu-button-add {
  flex: 20%;
}

.top-menu-button div {
  display: flex;
  gap: 10px;
}

.top-menu-button:not(.active):hover {
  background: linear-gradient(
    0deg,
    hsla(140, 3%, 77%, 0.08),
    hsla(140, 3%, 77%, 0.08)
  );
  cursor: pointer;
  color: #c4c7c5;
}

.puzzle-list {
  margin-top: 40px;
  margin-left: 14%;
  margin-right: 14%;
  display: flex;
  flex-direction: column;
}

h2 {
  color: #e3e3e3;
  font-weight: 475;
  font-size: 28px;
  font-variation-settings: 'GRAD' 0, 'opsz' 18;
  font-family: 'Google Sans';
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
}

.list-item {
  background-color: #28292a;
  /* border: 2px solid #4958fa7a; */
  border-radius: 24px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 24px;
  color: #e3e3e3;
  font-weight: 500;

  /* border-bottom: 1px solid #212149; */
  /* border-left: 4px solid #545ba4; */
}

.ml-auto {
  margin-left: auto;
}

.x-icon {
  display: flex;
  align-items: center;
  color: #c2e7ff;
}

.total-pieces {
  display: flex;
  align-items: center;
  color: #c2e7ff;
  font-weight: 600;
  font-size: 22px;
  gap: 12px;
}

.active {
  background: linear-gradient(
      0deg,
      hsla(0, 0%, 89%, 0.08),
      hsla(0, 0%, 89%, 0.08)
    ),
    #004a77;
  color: #c2e7ff;
  font-variation-settings: 'GRAD' 125;
}

.list-image {
  height: 48px;
  width: 48px;
  flex: 5%;
  display: flex;
  position: relative;
  border-radius: 16px;
  object-fit: cover;
}

.data {
  flex: 90%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.title {
  display: flex;
  flex: 20%;
}

.pieces {
  display: flex;
  flex: 20%;
  gap: 10px;
}

.creation-date {
  display: flex;
  flex: 20%;
}

.action-menu {
  display: flex;
  flex: 20%;
}

.play {
  padding: 10px 20px;
  margin-left: auto;
  display: flex;
  color: #e3e3e3;
  cursor: pointer;
  background: linear-gradient(
      0deg,
      hsla(0, 0%, 89%, 0.08),
      hsla(0, 0%, 89%, 0.08)
    ),
    #004a77;
  color: #c2e7ff;

  border-radius: 50px;
  gap: 10px;
  align-items: center;
  transition: 0.1s;
}

.play:hover {
  background-color: #004a77;
  cursor: pointer;
  /* color: #212149; */
}

.play span {
  font-weight: 600;
  font-size: 16px;
}

.create-wizard {
  /* margin-top: 100px; */
  margin-left: 14%;
  margin-right: 14%;
  display: flex;
  height: 100%;
  flex-direction: column;
  /* justify-content: center; */
}

.image-chooser {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #e3e3e3;
  font-weight: 475;
  font-size: 28px;
  font-variation-settings: 'GRAD' 0, 'opsz' 18;
  font-family: 'Google Sans';
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  transition: 0.1s;
  border-radius: 50px;
  padding: 60px;
  height: min-content;
  margin: auto;
}

.image-chooser:hover {
  background-color: #28292a;
  cursor: pointer;
}

.image-icon {
  padding: 60px;
  background: darksalmon;
  border-radius: 50px;
  display: flex;
  background-color: #004a77;
  color: #c2e7ff;
}

.choose-text {
  font-size: 42px;
  font-weight: 600;
  margin-top: 40px;
}

.creation-steps {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: auto;
}

.step {
  padding: 0px 194px 0px 0px;
  background-color: #28292a;
  margin-bottom: 60px;
  display: flex;
  border-radius: 50px;
  flex-direction: column;
  color: #c2e7ff;
  font-family: 'Google Sans';
  font-weight: 500;
  font-size: 20px;
  align-items: center;
  /* width: 100px; */
  /* height: 100px; */
  gap: 30px;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}

.step-number {
  /* margin-bottom: 20px; */
  padding: 20px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  justify-content: center;
  display: flex;
  border: 4px solid transparent;
}

.current {
  background: linear-gradient(
    0deg,
    hsla(140, 3%, 77%, 0.08),
    hsla(140, 3%, 77%, 0.08)
  );
  color: #c2e7ff;
  border: 4px solid #004a77;
}

.completed {
  background-color: #004a77;
  color: #c2e7ff;
}

.next {
  background: linear-gradient(
    0deg,
    hsla(140, 3%, 77%, 0.08),
    hsla(140, 3%, 77%, 0.08)
  );
  color: #c2e7ff;
}

.stage-data {
  display: flex;
  padding: 40px;
  justify-content: center;
  width: 100%;
  flex: 95%;
}

.input-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.spec-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

.spec-sheet {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: flex-start;
  width: 100%;
}

.spec-data {
  display: flex;
}

.canvas-wrapper {
  display: flex;
  height: 100%;
}

/* canvas {
  height: 500px;
  width: 1000px;
} */

canvas {
  background-color: #28292a;
}

h1 {
  color: rgb(196, 199, 197);
  margin: 0;
}
